import "babel-polyfill"
import "react-app-polyfill/jsdom"
import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "unfetch/polyfill"
import React from "react"
import ReactDOM from "react-dom"
import ReactGA from "react-ga"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./i18n"
import "semantic-ui-less/semantic.less"

ReactGA.initialize("UA-146462410-1", {
  titleCase: false,
})

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

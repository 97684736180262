import React, { Suspense } from "react"
import {
  BrowserRouter, Route, withRouter, Switch, Link
} from "react-router-dom"
import CookieConsent from "react-cookie-consent"
import { useTranslation } from "react-i18next"
import {
  Responsive, Container, Dropdown, Loader
} from "semantic-ui-react"
import {
  FaLinkedinIn, FaTwitter, FaFacebookF, FaLock
} from "react-icons/fa"

import { DesktopMenu } from "./components/Layout/DesktopMenu"
import { Footer } from "./components/Layout/Footer"

import { constants } from "./constants"
import styles from "./Routes.module.scss"

const Home = React.lazy(() => import("./pages/Home"))
const PartnerRouters = React.lazy(() => import("./pages/organizations/PartnerRouters"))
const EventRouters = React.lazy(() => import("./pages/events/EventRouters"))
const DocumentRouters = React.lazy(() => import("./pages/Documents/DocumentRouters"))
const ForumRouters = React.lazy(() => import("./pages/Forum/ForumRouters"))
const UserRouters = React.lazy(() => import("./pages/User/UserRouters"))
const AboutUs = React.lazy(() => import("./pages/AboutUs"))
const Search = React.lazy(() => import("./pages/Search"))
const LegalNotice = React.lazy(() => import("./pages/LegalNotice"))
const PageNotFound = React.lazy(() => import("./pages/404"))
const TAndC = React.lazy(() => import("./pages/T&C"))
const Confirmation = React.lazy(() => import("./pages/User/Confirmation"))
const Indicators = React.lazy(() => import("./pages/Indicators"))
const Cookies = React.lazy(() => import("./pages/Cookies"))

const Routes: React.FC = () => {
  const { t } = useTranslation()
  const { SOCIAL_LINKS } = constants
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <CookieConsent
        location="none"
        disableStyles
        containerClasses={[styles.cookieContainer, "noprint"].join(" ")}
        buttonClasses={styles.cookieButton}
        buttonText={t("ok")}
      >
        {t("cookies_msg")}
        {" "}
        <Link to="/cookies">{t("read_more")}</Link>
      </CookieConsent>
      <div
        className={[styles.socialBar, "noprint"].join(" ")}
      >
        <Container className={styles.container}>
          <div className={styles.socialContainer}>
            {!localStorage.getItem("authToken") ? (
              <Dropdown.Item as={Link} to="/user/login" className={styles.loginLink}>
                <FaLock />
                <p>{t("login")}</p>
              </Dropdown.Item>
            ) : (
              <div className={styles.profileLink}>
                <Dropdown
                  text={t("online")}
                  pointing
                  className={styles.online}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/user/profile">
                      {t("user_profile")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.removeItem("authToken")
                        localStorage.removeItem("user")
                        window.location.href = "/"
                      }}
                    >
                      {t("logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
            <div className={styles.socialLinks}>
              <a href={SOCIAL_LINKS.FACEBOOK} target="__blank">
                <FaFacebookF />
              </a>
              <a href={SOCIAL_LINKS.LINKEDIN} target="__blank">
                <FaLinkedinIn />
              </a>
              <a href={SOCIAL_LINKS.TWITTER} target="__blank">
                <FaTwitter />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div className="content">
        <Responsive
          minWidth={1199}
        >
          <DesktopMenu />
        </Responsive>
        <WithRouteScroll>
          <Suspense
            fallback={(
              <div style={{
                minHeight: 500,
                textAlign: "center",
                backgroundColor: "#0b4131",
                display: "flex",
                alignItems: "center"
              }}
              >
                <Loader size="massive" active inline="centered" />
              </div>
            )}
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/user" component={UserRouters} />
              <Route path="/announcement" component={EventRouters} />
              <Route path="/partners" component={PartnerRouters} />
              <Route path="/resources" component={DocumentRouters} />
              <Route path="/forum" component={ForumRouters} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/search/:query" component={Search} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/legal-notice" component={LegalNotice} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/indicators" component={Indicators} />
              <Route exact path="/terms-and-conditions" component={TAndC} />
              <Route exact path="/cookies" component={Cookies} />
              <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
        </WithRouteScroll>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

const ScrollToTop: React.FC<any> = ({ children, location: { pathname } }) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [pathname])

  return children || null
}

const WithRouteScroll = withRouter(ScrollToTop)

export { Routes }

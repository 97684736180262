import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import moment from "moment"
import translationEN from "./locales/en.json"
import translationFR from "./locales/fr.json"
import "moment/locale/fr"

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
}
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "fr",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "cookie"],
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
    },
  })
moment.locale(i18n.language)

export default i18n

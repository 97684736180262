import range from "lodash/range"

export const mapboxApiAccessToken =
  "pk.eyJ1IjoiYWJ1c2FpZC1tdXN0YWZhIiwiYSI6ImNqbDZqMm10MTJ4dTIzdm5zdWxrb3l4N20ifQ.0Zdlg7tg2oZhLjyXBFTwVA"

export const mapboxApi = "https://api.mapbox.com/geocoding/v5/mapbox.places"

export const mapsApi = "https://maps.googleapis.com/maps/api/geocode/json"
export const googleMapsApiKey = "AIzaSyAwPFde4ql-wiyW4QEcajpbV4WEdubMgqM"

export const constants = {
  years: range(1900, new Date().getFullYear() + 1),
  apiUrl: process.env.REACT_APP_API_BASE,
  SOCIAL_LINKS: {
    FACEBOOK: "https://www.facebook.com/panegmv/",
    LINKEDIN: "https://www.linkedin.com/showcase/panegmv/",
    TWITTER: "https://twitter.com/panegmv",
  },
}

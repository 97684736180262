import React from "react"
import {Responsive, Container, Menu, Image} from "semantic-ui-react"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import MenuLinks from "./MenuLinks"

export const DesktopMenu: React.FC = () => {
  const user = localStorage.getItem("user")
  if (!user) {
    localStorage.removeItem("authToken")
  }
  const {i18n} = useTranslation()
  const {language} = i18n
  const logo = `/images/logo-${language}.jpg`

  return (
    <Responsive className="desktop-menu noprint" minWidth={1199}>
      <Container>
        <Menu secondary>
          <Menu.Item className="logo" as={Link} to="/">
            <Image src={logo} />
          </Menu.Item>
          <Menu.Menu position="right">
            <MenuLinks />
          </Menu.Menu>
        </Menu>
      </Container>
    </Responsive>
  )
}

import React from "react"
import firebase from "firebase/app"
import {ApolloProvider} from "@apollo/react-hooks"
import {Routes} from "./Routes"
import CacheBuster from "./CacheBuster"
import client from "./apiClient"
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyB8yDd1x7XkhecSObcRTGeU7jSnAzsN3dM",
  authDomain: "green-wall-initiative.firebaseapp.com",
  databaseURL: "https://green-wall-initiative.firebaseio.com",
  projectId: "green-wall-initiative",
  storageBucket: "green-wall-initiative.appspot.com",
  messagingSenderId: "604067345497",
  appId: "1:604067345497:web:033b007a6cd8d363b2ed48",
  measurementId: "G-9HDXWC2JPL",
}

const App: React.FC = () => {
  React.useEffect(() => {
    firebase.initializeApp(firebaseConfig)
    firebase.analytics()
  }, [])
  return (
    <CacheBuster>
      {({loading, isLatestVersion, refreshCacheAndReload}) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload()
        }
        return (
          <ApolloProvider client={client}>
            <Routes />
          </ApolloProvider>
        )
      }}
    </CacheBuster>
  )
}

export default App

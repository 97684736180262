import * as React from "react"
import packageJson from "../package.json"

const appVersion: string = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

interface Props {
  children: (props: State) => React.ReactNode
}

interface State {
  loading: boolean
  isLatestVersion: boolean
  refreshCacheAndReload: () => void
}

class CacheBuster extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        // eslint-disable-next-line no-console
        console.log("Clearing cache and hard reloading...")
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((cacheNames) => {
            console.log(cacheNames)
            return Promise.all(
              cacheNames.map((cacheName) => caches.delete(cacheName))
            )
          })
        }
      },
    }
  }

  componentDidMount() {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version
        const currentVersion = appVersion

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        )
        if (shouldForceRefresh) {
          // eslint-disable-next-line no-console
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          )
          this.setState({loading: false, isLatestVersion: false})
        } else {
          // eslint-disable-next-line no-console
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          )
          this.setState({loading: false, isLatestVersion: true})
        }
      })
  }

  render() {
    const {loading, isLatestVersion, refreshCacheAndReload} = this.state
    const {children} = this.props
    return children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    })
  }
}

export default CacheBuster

import ApolloClient from "apollo-boost"
import i18n from "i18n"
import {constants} from "./constants"

const headers = {} as any

const {apiUrl} = constants

const client = new ApolloClient({
  uri: `${apiUrl}/graphql`,
  request: (operation) => {
    headers.language = i18n.language
    const token = localStorage.getItem("authToken")
    if (token) {
      headers.authorization = `Bearer ${token}`
    }
    operation.setContext({
      headers,
    })
  },
})

export default client

import React from "react"
import {Menu} from "semantic-ui-react"
import {Link, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import moment from "moment"

const MenuLinks: React.FC = () => {
  const [currentLang, setCurrentLang] = React.useState("")
  const {t, i18n} = useTranslation()
  const user = localStorage.getItem("user")
  if (!user) {
    localStorage.removeItem("authToken")
  }

  const {pathname} = useLocation()

  const currentURL = pathname.replace("/", "").split("/")[0]

  React.useEffect(() => {
    if (i18n.language === "fr") {
      setCurrentLang("en")
    } else if (i18n.language === "en") {
      setCurrentLang("fr")
    }
  }, [i18n.language])

  return (
    <>
      <Menu.Item
        as={Link}
        to="/partners"
        active={currentURL === "partners"}
        style={currentURL === "partners" ? {backgroundColor: "#8cBd3f"} : {}}>
        {t("partners")}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/announcement"
        active={currentURL === "announcement"}
        style={
          currentURL === "announcement" ? {backgroundColor: "#8cBd3f"} : {}
        }>
        {t("calendar")}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/resources"
        active={currentURL === "resources"}
        style={currentURL === "resources" ? {backgroundColor: "#8cBd3f"} : {}}>
        {t("resources")}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/forum"
        active={currentURL === "forum"}
        style={currentURL === "forum" ? {backgroundColor: "#8cBd3f"} : {}}>
        {t("forum")}
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="/indicators"
        active={currentURL === "indicators"}
        style={currentURL === "indicators" ? {backgroundColor: "#8cBd3f"} : {}}>
        {t("indicators")}
      </Menu.Item>
      {!localStorage.getItem("authToken") && (
        <Menu.Item as={Link} to="/user/signup" active className="signup">
          {t("inscription")}
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          if (i18n.language === "fr") {
            i18n.changeLanguage("en")
            setCurrentLang("fr")
          } else if (i18n.language === "en") {
            i18n.changeLanguage("fr")
            setCurrentLang("en")
          }
          moment.locale(i18n.language)
        }}>
        {currentLang.toUpperCase()}
      </Menu.Item>
    </>
  )
}

export default MenuLinks

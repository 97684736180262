import React from "react"
import {Container, Grid, List, Image} from "semantic-ui-react"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"

const Footer: React.FC = () => {
  const {t} = useTranslation()

  return (
    <footer className="noprint">
      <Container>
        <Grid coulums={4} stackable centered>
          <Grid.Row columns={3}>
            <Grid.Column>
              <List link>
                <List.Header style={{width: "75%"}} as="h4">
                  {t("animated_by_sos_sahel")}
                </List.Header>
                <List.Header as="h5">
                  2 Avenue Jeanne,
                  <br />
                  92600 Asnières-sur-Seine, France
                </List.Header>
                <List.Description>+33 1 46 88 93 70</List.Description>
                <List.Description>{t("contact_us_text")}</List.Description>
                <List.Description>
                  <a href="mailto:plateformegmv@sossahel.org">
                    plateformegmv@sossahel.org
                  </a>
                </List.Description>
                <a
                  href="http://sossahel.org/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <Image src="/images/footer_logo.jpg" />
                </a>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List link>
                <List.Header as="h4">{t("quick_access")}</List.Header>
                <List.Item>
                  <Link to="/partners">{t("partners")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/announcement">{t("calendar")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/resources">{t("resources")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/forum">{t("forum")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/about-us">{t("about_us")}</Link>
                </List.Item>
                <List.Item>
                  <Link to="/legal-notice">{t("legal_notice")}</Link>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List className="supportedBy">
                <List.Header as="h4">{t("supported_by")}</List.Header>
                <List.Item>
                  <a href="https://www.unenvironment.org/" target="__blank">
                    <Image src="/images/south_logo1.png" />
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://www.thegef.org/" target="__blank">
                    <Image src="/images/south_logo2.png" />
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://www.afd.fr/fr" target="__blank">
                    <Image src="/images/south_logo3.png" />
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://www.iucn.org/" target="__blank">
                    <Image src="/images/south_logo4.png" />
                  </a>
                </List.Item>
                <List.Item>
                  <a href="https://au.int/" target="__blank">
                    <Image src="/images/au.png" />
                  </a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <p className="copyRight">
          {t("copyright", {year: new Date().getFullYear()})}
        </p>
      </Container>
    </footer>
  )
}

export {Footer}
